import React from 'react';
import PropTypes from 'prop-types';
// import theme from 'styles/theme';
// import { Main } from 'styles/privilege-plans';
// import { PrivilagesStyled } from '../../styles/privilages';
// import 'styles/privilege-plans.scss';
// import 'styles/privilages.scss';
const PrivilegePlanTable = ({ togglePurchaseModal }) => (
  <main
  // className="privilege-plans-styled-main"
  >
    {/* <div className="privilages-styled"> */}
    <section className="pricing chart">
      <div className="container">
        <div className="pricing-table">
          <div
            className="pricing-single bg-lightGrey"
            style={{
              // backgroundColor: theme.colors.lightGrey,
              zIndex: 1,
              width: '50%',
            }}>
            <h2 className="price-title">Pro</h2>
            <div className="price-amount">
              <h3>
                <span>$600</span>
                <br />
                $375
              </h3>
            </div>
            <div className="price-features">
              <p>
                <span>1</span>
                &nbsp;member
              </p>
              <p>
                <span>Unlimited</span>
                &nbsp;projects
              </p>
              <p>
                Unlimited collaborators
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Collaborators can add comments and make edits.
                  </span>
                </span>
              </p>
              <p>
                Unlimited guest commenting
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Guests can only view and comment on the projects, without
                    the need of signing up or logging in.
                  </span>
                </span>
              </p>
              {/* <p>
                  <span>5</span>
                  &nbsp;editors
                  <span className="pop-info">
                    <svg width="15px" viewBox="0 0 7.6 7.6">
                      <path
                        d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                        fill="#6552ff"
                      />
                    </svg>
                    <span className="info-text">
                      Editors will have access to edit the projects and webpages
                      both. If additional editors are added, it will be charged
                      $4 per user per month.
                    </span>
                  </span>
                </p> */}
              <p>All integrations</p>
              <p>Bug Tracking</p>
              <p>Web app support</p>
              <p>-</p>
              <p>-</p>

              <p>
                <button
                  type="button"
                  className="price-button"
                  onClick={togglePurchaseModal(0)}>
                  Purchase Plan
                </button>
              </p>
            </div>
          </div>
          <div
            className="pricing-single pro-pricing"
            style={{
              // backgroundColor: theme.colors.lightGrey,
              width: '50%',
            }}>
            <h2 className="price-title">
              Team&nbsp;
              <span className="popular-badge">Most Popular</span>
            </h2>
            <div className="price-amount">
              <h3>
                <span>$1200</span>
                <br />
                $625
              </h3>
            </div>
            <div className="price-features">
              <p>
                <span>20</span>
                &nbsp;members
              </p>
              <p>
                <span>Unlimited</span>
                &nbsp;projects
              </p>

              <p>Unlimited collaborators</p>
              <p>Unlimited guest commenting</p>
              {/* <p>Unlimited editors</p> */}
              <p>All integrations</p>
              <p>Bug Tracking</p>
              <p>Web app support</p>
              <p>
                <span style={{ fontWeight: 600 }}>Custom Branding</span>
              </p>
              <p>
                <span style={{ fontWeight: 600 }}>CNAME</span>
              </p>
              <p>
                <button
                  type="button"
                  className="price-button active-button"
                  onClick={togglePurchaseModal(2)}>
                  Purchase Plan
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* </div> */}
  </main>
);

PrivilegePlanTable.propTypes = {
  togglePurchaseModal: PropTypes.func,
};

export default PrivilegePlanTable;
