import React, { useCallback, useState, useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import axios from 'axios';
import cn from 'clsx';
import isEmail from 'validator/lib/isEmail';

import Layout from 'components/Layout';
import GetStarted from 'components/GetStarted';
import Meta from 'components/Meta';

// import ContactUsContainer, {
//   ContactUsModal,
// } from 'components/ContactUs/ContactUs.styled';

// import { Main } from 'styles/privilege-plans';
import PrivilegePlanTable from '../components/PrivilegePlanTable';

// import { AppSumoTestimonials, PrivilagesStyled } from '../styles/privilages';
// import { TrustedBy } from '../styles';

import {
  TestimonialsContent,
  TestimonialsContentGoogle,
  brandLogos,
  customerNumber,
} from '../utils/contentProvider';
import stripeCountries from '../utils/stripeCountries';
import 'styles/privilages.scss';
import 'styles/privilege-plans.scss';
const productIDs = [
  'prod_KZm9HghFvRtEGo',
  'prod_KZmuz58E8gpH9d',
  'prod_KZmwYFUXitlZUr',
  // 'prod_LWEdVCfRVYmdN6',
  // 'prod_LWEe1Zouf2JfmX',
  // 'prod_LWEe0wuzY5ram6',
];

const PrivilegePlans = () => {
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [userCurrency, setUserCurrency] = useState('default');
  const [userEmail, setUserEmail] = useState('');
  const [fetchingLink, setFetchingLink] = useState(false);
  const [returningCoupon /* , setReturningCoupon */] = useState(null);

  /* eslint-disable prettier/prettier,indent */
  const togglePurchaseModal =
    (payload = false) =>
    () =>
      setShowPurchaseModal(payload);
  /* eslint-enable prettier/prettier,indent */

  const inputChangeHandler = (e) => {
    if (e.target.name === 'country') setUserCurrency(e.target.value);
    if (e.target.name === 'customer-email') setUserEmail(e.target.value);
  };

  const paySubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      setFetchingLink(true);

      axios
        .get(
          `https://web.ruttl.com/appmain/pay/checkout-ot?currency=${encodeURIComponent(
            stripeCountries
              .find((c) => c.countryCode === userCurrency)
              .currencyCode.split('--')[0],
          )}&prod=${encodeURIComponent(
            productIDs[showPurchaseModal],
          )}&email=${encodeURIComponent(userEmail)}`,
        )
        .then((re) => {
          window.location.href = re.data;
        })
        .catch(() => {
          setFetchingLink(false);
        });
    },
    [userCurrency, userEmail, showPurchaseModal],
  );

  // const daysCounter = useRef();

  // useEffect(() => {
  //   // Set the date we're counting down to
  //   const countDownDate = new Date('April 27, 2022 12:00:00').getTime();

  //   // Update the count down every 1 second
  //   const x = setInterval(() => {
  //     // Get today's date and time
  //     const now = new Date().getTime();

  //     // Find the distance between now and the count down date
  //     const distance = countDownDate - now;

  //     // Time calculations for days, hours, minutes and seconds
  //     const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     const hours = Math.floor(
  //       (distance - days * 1000 * 60 * 60 * 24) / (1000 * 60 * 60),
  //     );
  //     const minutes = Math.floor(
  //       (distance - (days * 24 + hours) * 60 * 60 * 1000) / (1000 * 60),
  //     );
  //     const seconds = Math.floor(
  //       (distance - ((days * 24 + hours) * 60 + minutes) * 60 * 1000) / 1000,
  //     );

  //     // Display the result in the element with id="demo"
  //     daysCounter.current.innerHTML = `<span>${days} <span>days</span></span> <span>:</span> <span>${hours} <span>hours</span></span> <span>:</span> <span> ${minutes} <span>mins</span></span> <span>:</span> <span>${seconds} <span>seconds</span></span>`;

  //     // If the count down is finished, write some text
  //     if (distance < 0) {
  //       clearInterval(x);
  //       daysCounter.current.innerHTML = 'DEAL HAS ENDED';
  //     }
  //   }, 1000);

  //   return () => clearInterval(x);
  // }, []);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector(anchor.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }, []);

  /* useEffect(() => {
    const pageURL = new URL(window.location.href);

    if (
      pageURL.searchParams.get('return') === 'true' &&
      pageURL.searchParams.get('email') &&
      pageURL.searchParams.get('cur') &&
      pageURL.searchParams.get('pi')
    ) {
      setShowPurchaseModal(-1);
      axios
        .get(
          `https://us-central1-ruttlp.cloudfunctions.net/stripeCreatePrivilegeReturnCode?email=${encodeURIComponent(
            pageURL.searchParams.get('email'),
          )}`,
        )
        .then((res) => {
          setShowPurchaseModal(Number(pageURL.searchParams.get('pi')));
          setUserEmail(pageURL.searchParams.get('email'));
          setReturningCoupon(res.data);
        })
        .catch(() => {
          setShowPurchaseModal(false);
        });
    }
  }, []); */

  return (
    <Layout>
      {({ toggleContactUs }) => (
        <>
          <Meta
            title="Choose a plan that suits you! 🤔 | Pricing and Plans for ruttl "
            description="Planning to use ruttl for your design projects? First 3 projects are free! If you need more, simply choose any pricing plan that suits your team size."
            url="https://ruttl.com/privilege-plans/"
          />
          <main className="privilege-plans-styled-main">
            <div className="privilages-styled">
              {/* <section className="privilage-hero">
                <div className="container">
                  <h1>Easter Sale</h1>
                  <h2>FLAT 40% OFF</h2>
                  <p>on all yearly and privilege plans</p>
                  <h3>Use Promo code &apos;EASTER40&apos; on checkout</h3>

                  <p style={{ margin: '25px 0 0', fontSize: 20 }}>
                    Deal ends in
                  </p>
                  <h3 style={{ marginTop: 15 }} ref={daysCounter}></h3>
                  <a href="#plans" className="button">
                    View plans
                  </a>
                </div>
              </section> */}
              <section className="pricing-hero">
                <div className="container" id="plans">
                  <h1>
                    Choose a lifetime deal that’s&nbsp;
                    <br />
                    right for you
                  </h1>
                </div>
              </section>

              <PrivilegePlanTable togglePurchaseModal={togglePurchaseModal} />

              <div className="styled-trusted-by">
                <div className="container">
                  <h3 className="center">Trusted by</h3>
                  <div className="flex justify-content-center align-items-center">
                    {brandLogos.map(({ name, image, id }) => (
                      <div key={id} className="logo-image">
                        <img src={image} alt={name} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* <section className="custom-plan" id="black-friday">
                <div className="container">
                  <div className="black-friday-box">
                    <div className="flex flex-wrap align-items-center">
                      <div className="custom-plan-img">
                        <img
                          src="/assets/img/black-friday-sale.png"
                          alt="ruttl pricing page illustration"
                        />
                      </div>
                      <div className="cutom-plan-text">
                        <h2 className="heading">
                          Get 75% off during Black Friday (26th Nov 2021 to 30th
                          Nov 2021)
                        </h2>
                        <p>
                          If you are a big enterprise with a bigger team or
                          someone with only one usecase, reach out to us and we
                          will prepare the best plan as per your requirement.
                        </p>
                        <button
                          type="button"
                          className="button"
                          onClick={toggleContactUsBilling(toggleContactUs)}>
                          Keep me notified
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}

              <div className="app-sumo-testimonials">
                <div className="container">
                  <h2 className="heading" style={{ marginBottom: 30 }}>
                    People are talking about ruttl
                  </h2>

                  <div className="app-sumo-testimonials-main">
                    <div className="flex flex-wrap">
                      {TestimonialsContent.map(
                        ({
                          img,
                          authorName,
                          authorDesignation,
                          description,
                          id,
                        }) => (
                          <div key={id} className="testimonial-col">
                            <div className="single-testimonial">
                              <div className="flex testimonial-head">
                                <div className="flex align-items-center flex-1">
                                  <div className="testimonial-img">
                                    <img src={img} alt={authorName} />
                                  </div>
                                  <div className="flex-1">
                                    <h3>{authorName}</h3>
                                    <p>{authorDesignation}</p>
                                  </div>
                                </div>
                                <div className="app-sumo-rating">
                                  {id === 0 ? (
                                    <>
                                      <img
                                        src="/assets/img/g2-stars.png"
                                        alt=""
                                        style={{ maxWidth: 120 }}
                                      />
                                      <img
                                        src="/assets/img/g2-logo.png"
                                        alt=""
                                        style={{ maxWidth: 40, marginTop: 8 }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src="/assets/img/app-sumo-rating.png"
                                        alt=""
                                        style={{ maxWidth: 120 }}
                                      />
                                      <img
                                        src="/assets/img/app-sumo-full.png"
                                        alt=""
                                        style={{ maxWidth: 80 }}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="testimonial-body">
                                <p>{description}</p>
                              </div>
                            </div>
                          </div>
                        ),
                      )}

                      {TestimonialsContentGoogle.map(
                        ({
                          img,
                          authorName,
                          authorDesignation,
                          description,
                          id,
                        }) => (
                          <div key={id} className="testimonial-col">
                            <div className="single-testimonial">
                              <div className="flex testimonial-head">
                                <div className="flex align-items-center flex-1">
                                  <div className="testimonial-img">
                                    <img src={img} alt={authorName} />
                                  </div>
                                  <div className="flex-1">
                                    <h3>{authorName}</h3>
                                    <p>{authorDesignation}</p>
                                  </div>
                                </div>
                                {/* <div className="app-sumo-rating">
                                  <img
                                    src="/assets/img/stars.svg"
                                    alt=""
                                    style={{ maxWidth: 100 }}
                                  />
                                  <img
                                    src="/assets/img/ruttl-logo.svg"
                                    alt=""
                                    style={{ maxWidth: 80, marginTop: 8 }}
                                  />
                                </div> */}
                              </div>

                              <div className="testimonial-body">
                                <p>{description}</p>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="founder-main">
                <div className="container">
                  <div className="flex flex-wrap">
                    <div className="founder-image">
                      <img src="/assets/img/siddhita-square.png" alt="" />
                    </div>
                    <div className="founder-desc">
                      <h2 className="heading">From the founder</h2>

                      <p>
                        {' '}
                        Hey there! 👋
                        <br />
                        I’m Siddhita, Co-Founder of Ruttl and we are super
                        excited to share our Privilege Plans with you! ✨
                      </p>

                      <p>
                        Ever worked on a web design project where everything was
                        smooth until it was time to collect the client feedback?
                        The conversation shifted to long email threads and
                        that’s where the issue started.
                      </p>

                      <p>
                        Nobody likes long emails threads + there’s a risk of
                        wasting your hard work just because you got the feedback
                        too late into the design process. Feels like collecting
                        feedback is much harder than actually working on the
                        project, right? Well, say goodbye to this issue and say
                        hello to Ruttl!
                      </p>

                      <p className="bold">Why was Ruttl built? 🤔</p>
                      <p>
                        The traditional way of collecting design feedback right
                        now is taking screenshots, pasting them on word files or
                        on emails and sharing the feedback. It gets even worse
                        when there are multiple people working on the same
                        project.
                      </p>

                      <p>
                        A few years back, my co-founder and I faced this same
                        issue when we started running our own design agency. We
                        found it extremely difficult to collaborate with
                        developers and other project stakeholders. This method
                        caused delays in project launch and required lot of
                        catch ups with the clients before finalizing on any
                        design. 😵
                      </p>

                      <p>
                        It was time for someone to step up and solve this
                        annoying issue. That’s why we decided to help out the
                        design community by creating Ruttl. ⚡
                      </p>

                      <p className="bold">What is Ruttl? ⚡</p>
                      <p>
                        Ruttl is a visual feedback and collaboration tool that
                        allows you to invite clients and teammates to leave
                        useful comments and make changes directly on live
                        websites. ✅
                      </p>

                      <p>
                        It solves the time-consuming, clunky design review
                        process with a host of features. And the best part?
                        Almost anyone in the creative industry like design teams
                        and freelance web designers and developers.can use
                        ruttl! 🎨
                      </p>

                      <p className="bold">What can you do with Ruttl? 🤔</p>
                      <p>
                        Using Ruttl is a modern and significantly much more
                        productive way of working on design projects. It’s
                        packed with powerful features that allow you to:
                      </p>
                      <ul>
                        <li>
                          🔹 Leave comments on websites to share your feedback
                        </li>
                        <li>
                          🔹 Edit design elements right away without touching
                          the code
                        </li>
                        <li>
                          🔹 Integrate with work tools like Slack and Trello
                        </li>
                        <li>
                          🔹 Record video comments for faster feedback sharing
                        </li>
                        <li>🔹 Replace images on websites</li>
                        <li>🔹 Review static design images</li>
                        <li>
                          🔹 Share projects with clients in the easiest way
                          possible
                        </li>
                      </ul>

                      <p>
                        And many more such features are waiting for you to
                        discover them! ⭐
                      </p>
                      <p>
                        {`Over ${customerNumber}+ happy users across multiple countries already
                        love us and have found it super useful! So, make your
                        work life easier by switching to the future of the
                        design review process with Ruttl today!`}
                      </p>

                      <p>
                        Cheers!
                        <br />
                        Siddhita ❤️
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <section className="faq-main" id="faq">
                <div className="container">
                  <div className="flex flex-wrap">
                    <div className="faq-heading-area">
                      <div className="sticky-heading">
                        <h2 className="heading">FAQ</h2>
                        <p>
                          Please check if your question has been answered here
                          before emailing us! We’d love to chat, but this saves
                          our time and yours!
                        </p>
                        <button
                          type="button"
                          className="button"
                          onClick={toggleContactUs}>
                          I have more questions
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 512 512">
                            <path
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="48"
                              d="M268 112l144 144-144 144M392 256H100"></path>
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="faq-question-main">
                      <Accordion allowZeroExpanded preExpanded={['a']}>
                        {[
                          {
                            id: 1,
                            uuid: 'a',
                            q: 'What happens after I make the payment?',
                            a: (
                              <>
                                Your ruttl account will be successfully upgraded
                                and it will reflect on your account.&nbsp;
                                <span className="bold">
                                  Please ensure that you submit the same email
                                  address with which you intend to create your
                                  ruttl account, or have already created it.
                                </span>
                              </>
                            ),
                          },
                          {
                            id: 2,
                            q: 'For how long can I access the benefits of these privilege plans?',
                            a: ' These privilege plans are available for lifetime, hence there is no expiry of their benefits once you purchase them.',
                          },
                          {
                            id: 3,
                            q: 'What is the difference between Collaborators and Members?',
                            a: 'Collaborators are the ones, whom you choose to invite to certain projects. They’ll be having view, comment or edit access only inside those projects to which they’re added. Members will be the ones, whom you choose to invite to your team & they’ll be having the following benefits just like the team owner - Unlimited projects, unlimited collaborators, unlimited guest commenting, and access to bug tracking & web app projects.',
                          },
                          // {
                          //   id: 4,
                          //   q: 'What if I wish to grant edit access to a collaborator, or add an extra editor in Privilege Pro plan?',
                          //   a: (
                          //     <>
                          //       By default, collaborators will only have View
                          //       and Comment access. If edit access has to
                          //       granted to users after the editor number
                          //       expires, it will pe charged at $4 per user
                          //       account.
                          //     </>
                          //   ),
                          // },
                          // {
                          //   id: 5,
                          //   q: 'What if I add an extra editor, or give edit access to a collaborator?',
                          //   a: (
                          //     <>
                          //       Once you add the extra editor, or give edit
                          //       access to a collaborator, their charge will be
                          //       added to your total billing, and will reflect in
                          //       the next month’s invoice.
                          //     </>
                          //   ),
                          // },

                          // {
                          //   id: 6,
                          //   q: 'What if I remove edit access for a collaborator or an extra editor?',
                          //   a: 'Once you remove the extra editor, or remove edit access from a collaborator, their charge will be subtracted from your total billing, and will reflect in the next month’s invoice.',
                          // },
                          {
                            id: 4,
                            q: 'Are these privilege plans refundable or transferrable?',
                            a: (
                              <>
                                Our privilege plans are refundable within 7
                                (seven) days of purchase. All our plans are
                                non-transferrable. If you wish to refund your
                                lifetime privilege plan within 7 days, please
                                email us at&nbsp;
                                <a href="mailto:support@ruttl.com">
                                  support@ruttl.com
                                </a>
                                .
                              </>
                            ),
                          },
                          {
                            id: 5,
                            q: 'What if I wish to change my privilege plan?',
                            a: (
                              <>
                                You can always upgrade your privilege plan.
                                Simply send us an email to&nbsp;
                                <a href="mailto:support@ruttl.com">
                                  support@ruttl.com
                                </a>
                                &nbsp;from your ruttl registered email ID, and
                                let us know which plan would you like to upgrade
                                to. We will send you a payment link and ask you
                                to pay the difference. Usually, the upgrades are
                                processed within 2 hours, but please give us
                                atleast 8-10 hours to process your request.
                              </>
                            ),
                          },
                        ].map(({ q, a, id, uuid }) => (
                          <AccordionItem
                            uuid={uuid}
                            key={id}
                            className="faq-single">
                            <AccordionItemHeading className="question">
                              <AccordionItemButton>
                                <h4>{q}</h4>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="answer">
                              {a}
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                      {/* <div className="faq-single">
                      <div className="question">
                        <h4>How can I pay for my ruttl plan?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          At the moment it&apos;s a manual process however
                          moving forward, you can choose to pay for your plan
                          with a credit or debit card, PayPal, or if you’re in
                          India, through UPI or Netbanking.
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>Can I switch between plans before they end?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          Yes, but you can only upgrade your plan. Just email us
                          at support@ruttl.com, and we’ll sort it out for you!
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>How long can I use ruttl for free?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          You can use ruttl for free for 45 days and work on two
                          projects during that time. After your trial is up, you
                          can choose a plan that’s perfect for your team and
                          your projects by emailing us at support@ruttl.com or
                          simply picking the one that works for you on this
                          page!
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>Which websites does ruttl work with?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          ruttl works with static, WordPress, Vue.js, and React
                          sites. In fact, you can use ruttl to work on pretty
                          much every website. Just paste the website URL and get
                          started!
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>Which integrations does ruttl currently allow?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          ruttl supports Slack, Trello, and White Label at the
                          moment. We’re working to include more, and we’d love
                          to know which ones you’d like. Email your suggestions
                          to us at support@ruttl.com
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>
                          I only want my clients to review, not edit the website
                          design — is this possible?
                        </h4>
                      </div>
                      <div className="answer">
                        <p>
                          Of course! You can choose to assign Inspect, Comment,
                          or View roles to anyone you invite. This way, your
                          managers, clients, and other key stakeholders can
                          review and/or leave comments while your team members
                          make edits to live websites to incorporate those
                          changes!
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>
                          How can I invite more team members on my project?
                        </h4>
                      </div>
                      <div className="answer">
                        <p>
                          Simply click on “Invite” and add the email addresses
                          of the people you want to work with on the project.
                          Assign the roles you’d like them to have, and just
                          like that, your website design is underway!
                        </p>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </section>
              <div className="get-started-center">
                <GetStarted />
              </div>
            </div>
          </main>
          <div
            id="purchase-modal"
            className={cn([
              'contact-us-modal',
              { show: typeof showPurchaseModal === 'number' },
            ])}>
            <div className="contact-us-container">
              <button
                type="button"
                className="button-unstyled close-button"
                disabled={fetchingLink}
                onClick={togglePurchaseModal(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="none"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="32"
                    d="M368 368L144 144M368 144L144 368"
                  />
                </svg>
              </button>
              {showPurchaseModal === -1 ? (
                <>
                  <p className="center">Fetching special offer...</p>
                </>
              ) : (
                <>
                  <h3 style={{ marginTop: 0, marginBottom: 24, fontSize: 24 }}>
                    {returningCoupon
                      ? 'Special Checkout Offer!'
                      : 'Purchase Lifetime Deal'}
                  </h3>
                  <img
                    src={
                      returningCoupon
                        ? '/assets/img/checkout-offer-10.png'
                        : '/assets/img/stripe-coupon-location.png'
                    }
                    alt="stripe coupon location"
                  />
                  <p className="center">
                    {returningCoupon && (
                      <span style={{ marginBottom: 10 }}>
                        We are giving you
                        <b> additional 10% discount </b>
                        only if you checkout now.&nbsp;
                      </span>
                    )}
                    <span className="center" style={{ marginTop: 0 }}>
                      Use promotion code
                      <span className="blue-text">
                        <b>
                          {` “${
                            returningCoupon ? returningCoupon.code : 'HELLO20'
                          }” `}
                        </b>
                      </span>
                      <br />
                      {returningCoupon ? (
                        <small>Offer expires in 20 minutes</small>
                      ) : (
                        <>on Stripe payment page and get 20% discount</>
                      )}
                    </span>
                  </p>
                  <form className="users-form" onSubmit={paySubmitHandler}>
                    <label htmlFor="customer-email">
                      <input
                        type="text"
                        name="customer-email"
                        id="customer-email"
                        placeholder="Email address"
                        required
                        onChange={inputChangeHandler}
                        value={userEmail}
                      />
                      <p
                        style={{
                          fontSize: 12,
                          lineHeight: '19px',
                          margin: '3px 0 0',
                          opacity: '0.7',
                        }}>
                        Note: This email address should be same as your ruttl
                        account.
                      </p>
                    </label>
                    <label className="custom-select" htmlFor="country">
                      <select
                        className="select"
                        name="country"
                        id="country"
                        value={userCurrency}
                        onChange={inputChangeHandler}>
                        <option value="default" disabled>
                          Select country
                        </option>
                        {stripeCountries.map(({ countryCode, countryName }) => (
                          <option key={countryCode} value={countryCode}>
                            {countryName}
                          </option>
                        ))}
                      </select>
                    </label>
                    <button
                      type="submit"
                      disabled={
                        userCurrency === 'default' ||
                        fetchingLink ||
                        !userEmail ||
                        !isEmail(userEmail)
                      }
                      className={`button button-fluid${
                        userCurrency === 'default' ||
                        !userEmail ||
                        !isEmail(userEmail)
                          ? ' button-disabled'
                          : ''
                      }`}>
                      {fetchingLink ? 'Redirecting...' : 'Pay now'}
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default PrivilegePlans;
